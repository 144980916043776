import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./components/header/Navbar";
import Footer from "./components/footer/Footer";
import ScrollToTopButton from "./components/pages/scrollToTop/ScrollToTopButton";
import axiosInstance from "./components/api/axiosInstance";
import WhatsApp from "./components/social-container/Whatsapp";
import OfferFromBtn from "./components/social-container/OfferFromBtn";
import AllSocialIcons from "./components/social-container/AllSocialIcons";
import Header from "./components/header/Header";

const Layout = () => {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await axiosInstance.get("/api/get-details");
        const data = response.data;
        setCompanyInfo(data);
      } catch (err) {
        console.error("Failed to fetch company info:", err);
        setError("Failed to load company information.");
      }
    };

    fetchCompanyInfo();
  }, []);

  return (
    <>
      {/* <Header info={companyInfo} /> */}
      <Navbar info={companyInfo} />
      {/* <ScrollToTopButton /> */}
      <main>
        <Outlet />
      </main>
      {/* <OfferFromBtn /> */}
      {/* <WhatsApp /> */}
      <AllSocialIcons />
      <Footer info={companyInfo} />
    </>
  );
};

export default Layout;
