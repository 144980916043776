import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { FaHeadphonesAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import "./AllSocialIcons.css";

const actions = [
  {
    icon: <FaWhatsapp style={{ fontSize: "30px", color: "green" }} />,
    name: "WhatsApp",
    link: `https://wa.me/9025441418`,
  },
  {
    icon: <FiPhone style={{ fontSize: "30px", color: "#1975d1" }} />,
    name: "Phone",
    link: `tel:9025441418`,
  },
];

export default function AllSocialIcons() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleActionClick = (link) => {
    window.location.href = link;
    handleClose();
  };

  return (
    <Box>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        className="speed-dial-container"
        icon={<FaHeadphonesAlt style={{ fontSize: "20px" }} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleActionClick(action.link)}
            sx={{
              transform: "scale(1.3)",
              padding: "8px",
              margin: "15px",
              "&:hover": {
                transform: "scale(1.4)", // Scale on hover
              },
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
