import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import { RiMenu5Fill } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import logo from "../../assets/logo/logo.png";
import iata_logo from "../../assets/logo/iataLogo.png";
import ScrollBar from "../farmorMotion/ScrollBar";

import Header from "./Header";

const Navbar = ({ info }) => {
  const location = useLocation();
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");

  // Update phone when info changes
  useEffect(() => {
    if (info && info.length > 0) {
      setPhone(info[0].phone);
      setMobile(info[0].mobile);
    }
  }, [info]);

  const isActive = (path) => (location.pathname === path ? "active" : "");

  const handleLinkClick = () => {
    const navbarCollapse = document.getElementById("navbarCollapse");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header className="navbar-section sticky-top ">
      <Header info={info} />
      <nav
        className="navbar navbar-expand-lg  py-lg-0 px-lg-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <Link to="#" className="navbar-brand ms-4 ms-lg-0">
          <img
            src={logo}
            alt="United Air Travels"
            className="header-nav-logo"
          />
        </Link>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <RiMenu5Fill className="fs-1 fw-bold" style={{ color: "#ffcc00" }} />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-auto p-4 p-lg-0">
            <Link
              // to="/#home"
              to="/"
              className={`nav-item nav-link ${isActive("/")}`}
              onClick={handleLinkClick}
            >
              Home
            </Link>
            <Link
              // to="/about#about"
              to="/about"
              className={`nav-item nav-link ${isActive("/about")}`}
              onClick={handleLinkClick}
            >
              About
            </Link>
            <Link
              to="/package"
              // to="/package#package"
              className={`nav-item nav-link ${
                isActive("/package") ||
                isActive("/singlePackageDetails") ||
                isActive("/BookingformwithPackage")
              }`}
              onClick={handleLinkClick}
            >
              Packages
            </Link>
            {/* <div className="nav-item dropdown">
              <Link
                to="/packages"
                className={`nav-link dropdown-toggle ${isActive("/packages")}`}
                // onClick={handleLinkClick}
                // data-bs-toggle="dropdown"
              >
                Packages
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/package1" className="dropdown-item">
                    Package 1
                  </Link>
                </li>
                <li>
                  <Link to="/package2" className="dropdown-item">
                    Package 2
                  </Link>
                </li>
                <li>
                  <Link to="/package3" className="dropdown-item">
                    Package 3
                  </Link>
                </li>
              </ul>
            </div> */}
            <Link
              to="/services"
              // to="/services#service"
              className={`nav-item nav-link ${
                isActive("/services") || isActive("/details-service/:id")
              }`}
              onClick={handleLinkClick}
            >
              Services
            </Link>
            <Link
              to="/contact"
              // to="/contact#form"
              className={`nav-item nav-link ${isActive("/contact")}`}
              onClick={handleLinkClick}
            >
              Contact
            </Link>
          </div>
          <div className="d-flex flex-column iata-container">
            <img src={iata_logo} alt="IATA logo" width="200px" height="60px" />

            <p className="mt-2">
              {" "}
              Certificate Number :
              <span className="fw-bold" style={{ color: "#0293cc" }}>
                14024754
              </span>
            </p>

            {/* <a href={`tel:${mobile}`} className="mt-2">
              <span className="ms-3" style={{ color: "#0092cc" }}>
                +91 {mobile}
              </span>
            </a> */}
          </div>
          {/* <div className="d-flex align-items-center justify-content-center flex-wrap call-us-section me-2">
            <div
              className="btn-lg-square  rounded-circle "
              style={{ backgroundColor: "#0092cc", marginTop: "20px" }}
            >
              <IoCallOutline className="fs-3  " style={{ color: "#ffff" }} />
            </div>

            <div className="">
              <small className="text-dark mb-0 text-center ">Call Us</small>
              <hr className="m-0" />
              <p className="navbar-contact  mb-0" style={{ fontSize: "20px" }}>
                <a href={`tel:${phone}`}>
                  <span className="text-warning ">{phone}</span>
                </a>
                <br />
                <a href={`tel:${mobile}`}>
                  <span style={{ color: "#0092cc" }}>{mobile}</span>
                </a>
              </p>
            </div>
          </div> */}
        </div>
      </nav>
      <ScrollBar />
    </header>
  );
};

export default Navbar;
