import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { IoIosPhonePortrait } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";

const PhoneModalComponent = ({ mobile, color, icons }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const whatsappLink = `https://wa.me/91${mobile}`;

  return (
    <span>
      <span className="mb-0">
        <span
          style={{ color: `${color || "#000"}`, cursor: "pointer" }}
          onClick={handleOpen}
        >
          {mobile && (
            <>
              {icons == "whatsapp" ? (
                <FaWhatsapp className="me-2" />
              ) : (
                <IoIosPhonePortrait className="me-2" />
              )}
              +91 {mobile}
            </>
          )}
        </span>
      </span>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "90%",
              sm: "400px",
            },
            maxHeight: "85vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
            overflowY: "auto",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Phone Number <br />
            <a href={`tel:${mobile}`} className="text-warning">
              <span>+91 {mobile}</span>
            </a>
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Call or scan to chat on{" "}
            <span className="text-success">WhatsApp</span> !
          </Typography>
          <div
            style={{ width: "100%", maxWidth: "256px", margin: "20px auto" }}
          >
            <QRCodeSVG
              value={whatsappLink}
              size={256}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <Typography variant="body2">
            Scan the QR code to chat with +91 {mobile}
          </Typography>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ mt: 2, backgroundColor: "#000 !important" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </span>
  );
};

export default PhoneModalComponent;
