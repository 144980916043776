import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Spinner from "./components/spinner/Spinner";
import "./App.css";
import SingleService from "./components/servicesMainPage/SingleService";

// Lazy load components
const HomePage = React.lazy(() => import("./components/Home/HomePage"));
const About = React.lazy(() => import("./components/About/About"));
const Packages = React.lazy(() => import("./components/Packages/Packages"));
const ServicePage = React.lazy(() =>
  import("./components/servicesMainPage/ServicePage")
);
const Contact = React.lazy(() => import("./components/contact/Contact"));
const TravelPackageForm = React.lazy(() =>
  import("./components/About/sample/TravelPackageForm")
);
const TravelPackageUpdate = React.lazy(() =>
  import("./components/About/sample/TravelPackageUpdate")
);
const TravelPackageList = React.lazy(() =>
  import("./components/About/sample/TravelpackageList")
);
const SampleAbout = React.lazy(() =>
  import("./components/About/sample/SampleAbout")
);
const SinglePackage = React.lazy(() =>
  import("./components/Packages/singlePackage/SinglePackage")
);
const ServiceDetails = React.lazy(() =>
  import("./components/service/serviceDetails/ServiceDetails")
);
const ErrorPages = React.lazy(() =>
  import("./components/pages/errorpage/ErrorPages")
);
const BookingformwithPackage = React.lazy(() =>
  import("./components/Packages/BookingformwithPackage/BookingformwithPackage")
);

function App() {
  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/package" element={<Packages />} />
            <Route path="/services" element={<ServicePage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/form" element={<TravelPackageForm />} />
            <Route path="/updateForm" element={<TravelPackageList />} />
            <Route path="/singlePackageDetails" element={<SinglePackage />} />
            <Route
              path="/BookingformwithPackage"
              element={<BookingformwithPackage />}
            />
            {/* <Route path="/details/:serviceName" element={<ServiceDetails />} /> */}
            <Route path="/details-service/:id" element={<SingleService />} />
          </Route>
          <Route path="*" element={<ErrorPages />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
