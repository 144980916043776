import React, { useState, useEffect } from "react";

import { FaPhoneAlt } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import PhoneModalComponent from "../social-container/PhoneModalComponent";
import { FaWhatsapp } from "react-icons/fa";

const Header = ({ info }) => {
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");

  useEffect(() => {
    if (info && info.length > 0) {
      setPhone(info[0].phone);
      setMobile(info[0].mobile);
    }
  }, [info]);
  return (
    <section
      style={{ backgroundColor: "#032040", padding: "5px" }}
      className="sticky-top"
    >
      <div
        className="container d-flex justify-content-end bg-waring"
        style={{ gap: "20px" }}
      >
        {/* <div
              className="btn-lg-square  rounded-circle "
              style={{ backgroundColor: "#0092cc", marginTop: "20px" }}
            >
              <IoCallOutline className="fs-3  " style={{ color: "#ffff" }} />
            </div> */}

        <p
          className="mb-0"
          style={{ fontSize: "clamp(1rem, 0.6875rem + 1vw, 1.25rem)" }}
        >
          {/* <a href={`tel:${phone}`}>
            <span className="text-warning">
              <FaPhoneAlt /> +91 {phone}
            </span>
          </a> */}

          <PhoneModalComponent mobile={phone} color="#f9b429" icons="whatsapp" />
        </p>
        <p
          className="mb-0"
          style={{ fontSize: "clamp(1rem, 0.6875rem + 1vw, 1.25rem)" }}
        >
          {/* <a href={`tel:${mobile}`} className="text-warning ">
            <span>
              <IoIosPhonePortrait /> +91 {mobile}
            </span>
          </a> */}
          <PhoneModalComponent mobile={mobile} color="#f9b429" />
        </p>
      </div>
    </section>
  );
};

export default Header;
