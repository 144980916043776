import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.css";
import Logo from "../../assets/logo/logo.png";
import paymentAccept from "../../assets/banner/payment-option.png";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { ImMobile } from "react-icons/im";
import { FaXTwitter } from "react-icons/fa6";

import AOS from "aos";
import PhoneModalComponent from "../social-container/PhoneModalComponent";

const Footer = ({ info }) => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    window.scrollTo({ top: "0 !important ", behavior: "smooth" });
  };

  const handleNational = () => {
    navigate("/package#package", { state: { filterPackageData: "National" } });
    handleLinkClick();
  };
  const handleInterNational = () => {
    navigate("/package#package", {
      state: { filterPackageData: "International" },
    });
    handleLinkClick();
  };
  const handleHajjAndUmrah = () => {
    navigate("/package#package", {
      state: { filterPackageData: "Hajj and Umrah" },
    });
    handleLinkClick();
  };
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="footerMainContainer sticky-bottom">
      {/* Footer Start */}
      <div className="footer">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className="footer-about">
                <h3>About Us</h3>
                <p>
                  Welcome to United Air Travels, your gateway to unforgettable
                  adventures and immersive travel experiences. Explore with us
                  and let your journey begin!
                </p>
              </div>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="row">
                <div className="col-sm-6 col-lg-4">
                  <div className="footer-link">
                    <h3>Company</h3>
                    {/* <Link to="/#home" onClick={handleLinkClick}> */}
                    <Link to="/" onClick={handleLinkClick}>
                      Home
                    </Link>
                    <Link to="/about" onClick={handleLinkClick}>
                      {/* <Link to="/about#about" onClick={handleLinkClick}> */}
                      About
                    </Link>
                    <Link to="/services" onClick={handleLinkClick}>
                      {/* <Link to="/services#service" onClick={handleLinkClick}> */}
                      Service
                    </Link>
                    <Link to="/package" onClick={handleLinkClick}>
                      {/* <Link to="/package#package" onClick={handleLinkClick}> */}
                      Packages
                    </Link>
                    <Link to="/contact" onClick={handleLinkClick}>
                      {/* <Link to="/contact#form" onClick={handleLinkClick}> */}
                      Contact
                    </Link>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="footer-link">
                    <h3>Packages</h3>
                    <p className="footer-Link-p" onClick={handleNational}>
                      National
                    </p>
                    <p className="footer-Link-p" onClick={handleInterNational}>
                      International
                    </p>
                    <p className="footer-Link-p" onClick={handleHajjAndUmrah}>
                      Hajj and Umrah
                    </p>
                  </div>
                </div>
                {/* <div className="col-sm-6 col-lg-3">
                  <div className="footer-link">
                    <h3>Payments</h3>
                    <p>All Payment modes are acceptable</p>
                    <div>
                      <img src={paymentAccept} alt="payment methods" />
                    </div>
                  </div>
                </div> */}
                <div className="col-sm-6 col-lg-4">
                  <div className="footer-link">
                    <h3>Address</h3>
                    <p>
                      {info?.[0]?.email ? (
                        <a href={`mailto:${info[0].email}`}>{info[0].email}</a>
                      ) : (
                        "No email available"
                      )}
                    </p>
                    <p>
                      {/* <a href={`tel:${info[0].phone}`}>{info[0].phone}</a> */}
                      {info?.[0]?.phone ? (
                        <PhoneModalComponent
                          mobile={info[0].phone}
                          color="#fff"
                        />
                      ) : (
                        "No phone available"
                      )}
                    </p>
                    <p>
                      {/* <a href={`tel:${info[0].mobile}`}>{info[0].mobile}</a> */}
                      {info?.[0]?.mobile ? (
                        <PhoneModalComponent
                          mobile={info[0].mobile}
                          color="#fff"
                          icons="whatsapp"
                        />
                      ) : (
                        "No mobile available"
                      )}
                    </p>
                    <p>
                      {info?.[0]?.address ? (
                        <a
                          href={info[0].location}
                          // style={{ color: "#fff" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${info[0].address.street}, ${info[0].address.city}, ${info[0].address.pincode}, ${info[0].address.state}, ${info[0].address.country}`}
                        </a>
                      ) : (
                        "No address available"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-contact">
            <div className="row align-items-center">
              <div className="col-md-4 col-lg-3">
                <div className="footer-logo">
                  <img src={Logo} alt="united air travels logo" />
                </div>
              </div>
              <div className="col-md-4 col-lg-6">
                <div className="footer-link">
                  <h3>Payments</h3>
                  <p>All Payment modes are acceptable</p>
                  <div>
                    <img src={paymentAccept} alt="payment methods" />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="footer-social">
                  <Link
                    to="https://www.facebook.com/unitedairtravelsramnad"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f" />
                  </Link>

                  <Link to="https://www.instagram.com/united_air_travels">
                    <i className="fab fa-instagram" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="copy-text">
                  <p className="text-light">
                    © <span className="text-warning">United Air Travels</span>.
                    All Rights Reserved.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <p className="text-light" style={{ color: "#fff" }}>
                  Designed by
                  <Link
                    to="https://hellowtec.com/"
                    className="ms-1 text-warning"
                    target="_blank"
                  >
                    HELLO TECHNOLOGIES
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </div>
  );
};

export default Footer;
