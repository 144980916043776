import React, { useEffect, useState } from "react";
import axiosInstance from "../api/axiosInstance";
import { useParams, useNavigate } from "react-router-dom";
import SubAllBanner from "../pages/subAllBanner/SubAllbanners";
import Banner from "../service/serviceDetails/details/HujjAndUmrah/Banner";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import "./SingleService.css";

import AOS from "aos";

const SingleService = () => {
  const { id: serviceId } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const HandleConactBtn = () => {
    if (serviceData.servicename.toLowerCase() == "hajj and umrha package") {
      navigate("/package#package", {
        state: { filterPackageData: "Hajj and Umrah" },
      });
    } else {
      navigate("/contact#contactFormForService");
    }
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    const fetchServiceData = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/get-service/${serviceId}`
        );
        setServiceData(response.data);
      } catch (error) {
        setError("Failed to load service data.");
        navigate("/error");
      }
    };

    fetchServiceData();
  }, [serviceId, navigate]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!serviceData) {
    return <div>Loading...</div>;
  }

  return (
    <section className="single-service-container">
      {serviceData.servicename.toLowerCase() === "hajj and umrah" ? (
        <Banner title={serviceData.servicename} />
      ) : (
        <SubAllBanner title={serviceData.servicename} />
      )}

      <div data-aos="fade-up">
        <h2 className="text-info text-uppercase fw-bold my-4 text-center">
          {serviceData.servicename}
        </h2>
        <p className="text-center">{serviceData.description}</p>
        <hr className="m-2" />
      </div>
      <div className="container service-container-single-container">
        <div className="single-service-data">
          {serviceData.serviceModel.map((model) => (
            <div key={model.id} className="service-model">
              <div
                className="details-container p-2 rounded"
                style={{ border: "2px solid #f2f2f2" }}
                data-aos="fade-up"
              >
                <h2
                  className="service-details-container-title my-2"
                  data-aos="fade-up"
                >
                  {model.title}
                </h2>
                <p data-aos="fade-up">{model.description.join(" ")}</p>
                {model.requirements && model.requirements.length > 0 ? (
                  <ul data-aos="fade-up">
                    {model.requirements.map((req, reqIndex) => (
                      <li
                        key={reqIndex}
                        data-aos="fade-up"
                        className="p-2 rounded"
                        style={
                          req.procedure
                            ? { border: "2px solid #f2f2f2", padding: "10px" }
                            : {}
                        }
                      >
                        {req.documents && req.documentItems.length > 0 && (
                          <>
                            <h5 className="fw-bold mb-2">{req.documents}:</h5>
                            {/* <hr className="w-25" /> */}
                            {req.documentItems.map((item, itemIndex) => (
                              <p key={itemIndex}>
                                <VscDebugBreakpointLog className="me-2 text-warning" />
                                {item}
                              </p>
                            ))}
                            <br />
                          </>
                        )}
                        {req.procedure && (
                          <>
                            <h5 className="fw-bold mb-2">{req.procedure}</h5>
                            <hr className="w-25" />
                          </>
                        )}

                        {req.procedureItems &&
                          req.procedureItems.length > 0 &&
                          req.procedureItems.map((item, itemIndex) => (
                            <p key={itemIndex}>
                              <VscDebugBreakpointLog className="me-2 text-info" />
                              {item}
                            </p>
                          ))}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No requirements available.</p>
                )}
              </div>
              <div className="image-container " data-aos="fade-up">
                <img src={model.image} alt={model.title} />
              </div>
            </div>
          ))}
        </div>
        <aside className="contact-section" data-aos="fade-up">
          <h2>Contact Us for Assistance</h2>
          <p>
            Have questions or need further information about our visa stamping
            services? Reach out to us for assistance.
          </p>
          <button onClick={HandleConactBtn} className="contact-button">
            Contact Us
          </button>
        </aside>
      </div>
    </section>
  );
};

export default SingleService;
