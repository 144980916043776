import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../api/axiosInstance";
import "./offerPopup.css";

const OfferPopUp = ({ show, onClose }) => {
  const [Packages, setPackages] = useState([]);
  const [cities, setCities] = useState([]);
  const [errorShown, setErrorShown] = useState(false);
  const [successShown, setSuccessShown] = useState(false);
  const [optOut, setOptOut] = useState(false);

  const showError = (message) => {
    if (!errorShown) {
      toast.error(message);
      setErrorShown(true);
      setTimeout(() => setErrorShown(false), 4000);
    }
  };

  const showSuccess = (message) => {
    if (!successShown) {
      toast.success(message);
      setSuccessShown(true);
      setTimeout(() => setSuccessShown(false), 4000);
    }
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axiosInstance.get("/api/packages");
        const packageData = response.data.data;
        setPackages(packageData);
      } catch (error) {
        console.error("Error fetching the packages data:", error);
      }
    };

    fetchPackages();
  }, []);

  const handlePackageChange = (packageId) => {
    const selectedPackage = Packages.filter(
      (pkg) => pkg.category === packageId
    ).flatMap((item) => item.package);
    setCities(selectedPackage || []);
  };

  const handleOptOut = () => {
    localStorage.setItem("popupOptOut", "true");
    setOptOut(true);
    onClose();

    setTimeout(() => {
      localStorage.removeItem("popupOptOut");
    }, 600000);
  };

  if (!show) return null;

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(
        /^[a-zA-Z\s]+$/,
        "Name should not contain numbers or special characters"
      )
      .max(15, "Name must be at most 15 characters")
      .test(
        "no-leading-space",
        "Name cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .required("Name is required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address format"
      )
      .min(12, "Email address is too short")
      .test(
        "no-leading-space",
        "Email cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^[1-9][0-9]{9}$/, {
        message:
          "Phone number must be exactly 10 digits and should not start with 0",
        excludeEmptyString: true,
      })
      .test(
        "no-leading-space",
        "Phone cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .required("Phone is required"),
    package: Yup.string()
      .test(
        "no-leading-space",
        "Package cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .required("Package is required"),
    city: Yup.string()
      .test(
        "no-leading-space",
        "City cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .required("City is required"),
    message: Yup.string()
      .test(
        "no-leading-space",
        "Message cannot start with a space",
        (value) => !value || !/^\s/.test(value)
      )
      .max(200, "Type must be at most 200 characters")
      .min(50, "Type must be at least 50 characters")
      .required("Message is required"),
  });

  return (
    <div className="popup-overlay offer-popup-container">
      <div className="popup-content">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>
        <div className="offer-book-header">
          <h4 className="mb-1">
            Save up to <span style={{ color: "#ffe603" }}>40% OFF</span>
          </h4>
          <p>
            We’ll be needing some of your basic details to help you better with
            your trip
          </p>
        </div>
        <h4 className="mt-3 text-warning">Get in Touch with us</h4>
        <hr className="w-25" />
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            package: "",
            city: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const res = await axiosInstance.post("/api/add-booking-details", {
                name: values.name,
                email: values.email,
                phone: values.phone,
                package: values.package,
                location: values.city,
                message: values.message,
              });
              if (res) {
                alert("Booking details added successfully");
                resetForm();
                localStorage.setItem("popupOptOut", "true");
                setOptOut(true);
                onClose();
              } else {
                alert("Error adding booking details");
              }
            } catch (error) {
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                alert(error.response.data.message);
              } else {
                alert("Error adding booking details");
              }
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Your Name"
                    className="form-control"
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Your Email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <Field
                    name="phone"
                    type="text"
                    placeholder="Your Phone Number"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="package">Select Package</label>
                  <Field
                    as="select"
                    name="package"
                    className="form-select"
                    style={{ padding: "10px" }}
                    onChange={(e) => {
                      const packageId = e.target.value;
                      setFieldValue("package", packageId);
                      handlePackageChange(packageId);
                    }}
                  >
                    <option value="" disabled>
                      Choose Package...
                    </option>
                    {Packages.map((pkg) => (
                      <option key={pkg.id} value={pkg.id}>
                        {pkg.category}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="package"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="city">Select City</label>
                  <Field
                    as="select"
                    name="city"
                    className="form-select"
                    style={{ padding: "10px" }}
                  >
                    <option value="" disabled>
                      Choose City...
                    </option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        <p> {city.title}</p>
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="city" component="div" className="error" />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <Field
                    as="textarea"
                    name="message"
                    placeholder="Your Message"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="offer-popup-container-btn">
                <button
                  type="submit"
                  className="btn btn-primary offer-book-bnt"
                >
                  Send Message
                </button>

                <button
                  type="button"
                  className="btn btn-secondary "
                  onClick={handleOptOut}
                >
                  Do not show this popup again
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </div>
  );
};

export default OfferPopUp;
